import React from "react"
import PubSub from 'pubsub-js';
import Sticky from 'react-sticky-el';
import scrollIntoView from 'scroll-into-view';
//import { observe } from '../utils/intersection-observer';

class Menu extends React.Component {
    constructor(props) {
        super(props);
    
        this.state = {
            index: 0,
            olHeight: 0,
            liHeight: 0,
            olTransformY: 0
        }

        this.ol = React.createRef();
        this._onMouseEnter = this._onMouseEnter.bind(this)
        this._onFixedToggle = this._onFixedToggle.bind(this)
        this._resize = this._resize.bind(this)
    }

    componentDidMount(){
        document.addEventListener("resize", this._resize)
        this._resize();

        this._getMenuBoundingBottom()

        // PubSub.subscribe('BG_CLICK', (e) => {
        //     document.querySelector("nav li:first-child .title").click()
        // })

        PubSub.subscribe('PROJET_ENTER', (e,d) => {
            const olTransformY = d.index * this.state.liHeight
            this.setState({
                index: d.index,
                olTransformY: olTransformY
            }, () => {
                this._getMenuBoundingBottom()
            })
        })

        const li = this.ol.current.childNodes;
        PubSub.subscribe('PROJET_HOVER', (e,d) => {
            li.forEach((item, index) => {
                item.classList.remove("active")   
            });

            li.forEach((item, index) => {
                if(d.index === index)
                    item.classList.add("active")
            });

        });
    }

    _resize(){
        const olHeight = this.ol.current.getBoundingClientRect().height;
        //12 = padding bottom
        const liHeight = document.querySelector("h1").getBoundingClientRect().height + 12; 
        console.log(liHeight, olHeight)
        this.setState({
            olHeight: olHeight,
            liHeight: liHeight
        })
    }

    _getMenuBoundingBottom(){
        const ol = this.ol.current;
        const menuBottom = ol.getBoundingClientRect().bottom
      
        PubSub.publish('MENU_CHANGE', {
            menuBottom: menuBottom
        })
    }

    _onMouseEnter(slug){
        PubSub.publish("MENU_HOVER", {slug: slug})
    }

    _onClick(slug, index){
        const elem = document.querySelector("#"+slug)
        const {liHeight, olHeight} = this.state
        //const length = this.props.data.length

        let topOffset = olHeight - (index * liHeight)
        //console.log(elem,olHeight, liHeight, (index * liHeight),topOffset)
        if('ontouchstart' in window)topOffset = 0
        scrollIntoView(elem, {
            align: {
                top: 0,
                topOffset: topOffset
            }
        })
    }

    _onFixedToggle(fixed){
        //console.log("fixed",fixed)
        const stickyClass = !fixed ? 'sticky' : ''
        this.setState({
            stickyClass: stickyClass
        })
        
        if(!fixed){
            this._getMenuBoundingBottom()
        }
    }

    render() {
        const {index, olTransformY, stickyClass} = this.state
        const {data,menuClass} = this.props
        //console.log(data)
        const style = {
            transform: 'translateY(-'+olTransformY+'px)' 
        }
        const alphabet = ("abcdefghijklmnopqrstuvwxyz").split("");
        return (
            <div className="nav-wrap">
                <div className="hidden-m">
                    <nav data-index={index} className={menuClass+' '+stickyClass}>
                        <ol 
                        type='A' 
                        style={style} 
                        ref={this.ol}>
                            {data.map((node, key) => (
                                <li 
                                key={key} 
                                className={key<index ? 'hidden' : ''}
                                onMouseEnter={() => this._onMouseEnter(node.slug)}>
                                    <div className="index">{alphabet[key]}. </div>
                                    <div className="title" 
                                    onClick={()=>this._onClick(node.slug, key)}>
                                        {node.title}
                                    </div>
                                </li>
                            ))}
                        </ol>
                    </nav>
                </div>
                <div className="hidden-xs">
                    <Sticky onFixedToggle={this._onFixedToggle}>
                        <nav data-index={index} className={menuClass+' '+stickyClass}>
                            <ol 
                            type='A' 
                            style={style} 
                            ref={this.ol}>
                                {data.map((node, key) => (
                                    <li 
                                    key={key} 
                                    className={key<index ? 'hidden' : ''}
                                    onMouseEnter={() => this._onMouseEnter(node.slug)}>
                                        <div className="index">{alphabet[key]}. </div>
                                        <div 
                                        className="title" 
                                        onClick={()=>this._onClick(node.slug, key)}>
                                            {node.title}
                                        </div>
                                    </li>
                                ))}
                            </ol>
                        </nav>
                    </Sticky>
                </div>
            </div>
            
        )
        
    }
}

export default Menu