import React, { Component } from 'react';
import PubSub from 'pubsub-js';

// import SawS from "../images/saw-s.inline.svg";
// import SawA from "../images/saw-a.inline.svg";
// import SawW from "../images/saw-w.inline.svg";
import SawS from '../images/saw-s-n.inline.svg';
import SawA from '../images/saw-a-n.inline.svg';
// import SawW from '../images/saw-w-n.inline.svg';
import SawWw from '../images/saw-w-w-n.inline.svg';
import SawWe from '../images/saw-w-e-n.inline.svg';

class Bg extends Component {
  constructor(props) {
    super(props);

    this.state = {
      bgClass: '',
    };

    // this._onResize = this._onResize.bind(this);
    this._onScroll = this._onScroll.bind(this);
  }

  componentDidMount() {
    const { context } = this.props;
    if (context === 'header') {
      this.refs.bg.addEventListener('click', () => {
        this._fall();
      });

      document.addEventListener('scroll', this._onScroll);
    }

    //document.addEventListener("resize", this._onResize)
    this._colorSvgs();
  }

  _onResize() {
    let oHeight = 0;
    document.querySelectorAll('svg').forEach((el, idx) => {
      if (idx === 0) oHeight = el.getBoundingClientRect().width;

      el.style.width = oHeight + 'px';
      el.style.height = oHeight + 'px';
    });
  }

  _colorSvgs() {
    document.querySelectorAll('.heads .tint').forEach((el, idx) => {
      const letter = el.dataset.letter;
      console.log(letter);
      el.style.fill = this._getColorByLetter('heads', letter);
    });

    document.querySelectorAll('.tails .tint').forEach((el, idx) => {
      const letter = el.dataset.letter;
      el.style.fill = this._getColorByLetter('tails', letter);
    });
  }

  _getColorByLetter(side, letter) {
    const { colors } = this.props;
    if (side === 'heads') {
      switch (letter) {
        case 's':
          return colors.colorSHeads;
        case 'a':
          return colors.colorAHeads;
        case 'w':
          return colors.colorWHeads;
        default:
          return '#FF4F02';
      }
    } else {
      switch (letter) {
        case 's':
          return colors.colorSTails;
        case 'a':
          return colors.colorATails;
        case 'w':
          return colors.colorWTails;
        default:
          return '#FF4F02';
      }
    }
  }

  _fall() {
    PubSub.publish('BG_OUT');

    const footerTop = document
      .querySelector('footer')
      .getBoundingClientRect().top;
    console.log(footerTop);
    this.refs.bg.style.top = footerTop + 'px';
    this.refs.bg.style.opacity = 0;
    setTimeout(() => {
      this.refs.bg.style.display = 'none';
    }, 2000);
  }

  _onScroll() {
    const bgTop = this.refs.bg.getBoundingClientRect().bottom;
    if (bgTop <= 0) {
      document.removeEventListener('scroll', this._onScroll);
      PubSub.publish('BG_OUT');
    }
  }

  render() {
    return (
      <div className="bg" ref="bg">
        <div className="flex">
          <div className="col-xs-6 col-md-3">
            <div className="sawS">
              <div className="inside">
                <div className="flip-wrap">
                  <div className="flip">
                    <div className="heads">
                      <SawS />
                    </div>
                    <div className="tails">
                      <SawS />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xs-6 col-md-3">
            <div className="sawA">
              <div className="inside">
                <div className="flip-wrap">
                  <div className="flip">
                    <div className="heads">
                      <SawA />
                    </div>
                    <div className="tails">
                      <SawA />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xs-12 col-md-6">
            <div className="sawW">
              <div className="flex">
                <div className="inside">
                  <div className="flip-wrap">
                    <div className="flip">
                      <div className="heads">
                        <SawWw />
                      </div>
                      <div className="tails">
                        <SawWw />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="inside">
                  <div className="flip-wrap">
                    <div className="flip">
                      <div className="heads">
                        <SawWe />
                      </div>
                      <div className="tails">
                        <SawWe />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="inside">
                <div className="flip-wrap">
                  <div className="flip">
                    <div className="heads">
                      <SawW />
                    </div>
                    <div className="tails">
                      <SawW />
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        {/* <div className="row bottom-xs">
          <div className="col-xs-6 col-md-3 sawS">
            <div className="flip-wrap">
              <div className="flip">
                <div className="heads">
                  <SawS />
                </div>
                <div className="tails">
                  <SawS />
                </div>
              </div>
            </div>
            <div className="flip-wrap">
              <div className="flip">
                <div className="heads">
                  <SawS />
                </div>
                <div className="tails">
                  <SawS />
                </div>
              </div>
            </div>
          </div>
          <div className="col-xs-6 col-md-3 sawA">
            <div className="flip-wrap">
              <div className="flip">
                <div className="heads">
                  <SawA />
                </div>
                <div className="tails">
                  <SawA />
                </div>
              </div>
            </div>
            <div className="flip-wrap">
              <div className="flip">
                <div className="heads">
                  <SawA />
                </div>
                <div className="tails">
                  <SawA />
                </div>
              </div>
            </div>
          </div>
          <div className="col-xs-6 col-md-3 sawW">
            <div className="flip-wrap">
              <div className="flip">
                <div className="heads">
                  <SawW />
                </div>
                <div className="tails">
                  <SawW />
                </div>
              </div>
            </div>
          </div>
          <div className="col-xs-6 col-md-6 sawW end-xs">
            <div className="flip-wrap">
              <div className="flip">
                <div className="heads">
                  <SawW />
                </div>
                <div className="tails">
                  <SawW />
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    );
  }
}

export default Bg;
