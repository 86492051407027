import React from 'react'
import Bg from "../components/bg"

class Footer extends React.Component {
    render () {
        const {data,colors} = this.props
        return (
            <footer>
                <Bg color="orange" context="footer" colors={colors} />

                <div className="inner">
                    <div className="colophon">
                        <div className="row">
                            <div className="col-xs-12 col-md-6">
                                <address dangerouslySetInnerHTML={{ __html: data.adress.childMarkdownRemark.html }} />
                            </div>
                            <div className="col-xs-12 col-md-6">
                                <ul>
                                    <li><a rel="noopener noreferrer" target="_blank" href={data.contact}>CONTACT</a></li>
                                    <li><a rel="noopener noreferrer" target="_blank" href={data.instagram}>INSTAGRAM</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xs-12 col-md-6">
                            <div className="texte small">
                                <div 
                                dangerouslySetInnerHTML={{ __html: data.about.childMarkdownRemark.html }} 
                                
                                />
                            </div>
                        </div>
                        <div className="col-xs-12 col-md-6">
                            <div className="texte small">
                                <div 
                                dangerouslySetInnerHTML={{ __html: data.credits.childMarkdownRemark.html }} 
                                
                                />
                            </div>
                        </div>
                    </div>

                    
                </div>
                
                
            </footer>
        )
    }
}

export default Footer