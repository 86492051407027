import React, { Component } from 'react';
import Helmet from "react-helmet";
//import PubSub from 'pubsub-js';

import favicon from "../images/icons/favicon.ico";
import favicon16 from "../images/icons/favicon-16x16.png";
import favicon32 from "../images/icons/favicon-32x32.png";
import iconApple from "../images/icons/apple-touch-icon.png";
//import manifest from "../images/icons/site.webmanifest"


class Seo extends Component {
    // componentDidMount(){
    //     PubSub.subscribe("IS_CAROUSEL", (e,d) =>{
    //         console.log(e)
    //         console.log(d)
    //     })
    // }
    render() {
        //console.log(Favicon16)
        const {data, bodyClass} = this.props
        //console.log(bodyClass)
        return (
            <Helmet>
                <html lang="en" className={bodyClass}/>
                <meta charSet="utf-8" />
                <title>{data.subtitle}</title>
                <meta name="description" content={data.subtitle} />
                
                <link rel="shortcut icon" type="image/x-icon" href={favicon} />
                <link rel="apple-touch-icon" sizes="180x180" href={iconApple} />
                <link rel="icon" type="image/png" sizes="32x32" href={favicon32} />
                <link rel="icon" type="image/png" sizes="16x16" href={favicon16} />
                {/* <link rel="manifest" href={manifest} /> */}
                <meta name="theme-color" content="#FF4F02" />

                <meta property="og:url" content="https://saw.earth" />
                <meta property="og:title" content={data.subtitle} />
                <meta property="og:description" content={data.subtitle} />
                <meta property="og:image" content={data.image.file.url} />

                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:site" content="@saw" />
                <meta name="twitter:creator" content="@saw" />
                <body  />
            </Helmet>
        );
    }
}

export default Seo;