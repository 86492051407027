import React, { Component } from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

class Figure extends Component {
  render() {
    const { input, src, fileName } = this.props;
    // console.log(src);
    // console.log(input.images.sources);
    const isGif = fileName.indexOf('.gif') > -1;
    // return null;

    return (
      <figure>
        {isGif && (
          <img
            src={src}
            srcSet={input.images.sources.srcSet}
            sizes={input.images.sources.sizes}
            alt={fileName}
          />
        )}
        {!isGif && (
          <GatsbyImage image={getImage(input)} alt={fileName ? fileName : ''} />
        )}
      </figure>
    );
  }
}

export default Figure;
