import React from 'react';
import PubSub from 'pubsub-js';
import Figure from './figure';
// import { GatsbyImage, getImage } from 'gatsby-plugin-image';

class Projet extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      index: 0,
      indexTop: 0,
      projetClass: '',
      heroClass: '',
      menuBottom: 0,
    };

    this._onClick = this._onClick.bind(this);
    this._onMouseEnter = this._onMouseEnter.bind(this);
    this._onMouseLeave = this._onMouseLeave.bind(this);
    this._onScroll = this._onScroll.bind(this);
    this._resize = this._resize.bind(this);
    this.selector = React.createRef();
  }

  componentDidMount() {
    // document.addEventListener("resize", this._resize)
    // this._resize();

    //console.log()
    //const {index, length} = this.props
    const alphabet = 'abcdefghijklmnopqrstuvwxyz'.split('');
    this.setState({
      index: alphabet[this.props.index],
      //indexTop: ((length-index) * 44) + 38
    });

    document.addEventListener('scroll', this._onScroll);

    PubSub.subscribe('MENU_CHANGE', (e, d) => {
      this.setState({
        menuBottom: d.menuBottom,
      });
    });

    PubSub.subscribe('MENU_HOVER', (e, d) => {
      if (this.props.data.slug === d.slug) {
        this.setState({
          heroClass: 'active',
        });
      } else {
        this.setState({
          heroClass: '',
        });
      }
    });
  }

  _resize() {
    const { index, length } = this.props;
    const liHeight =
      document.querySelector('h1').getBoundingClientRect().height + 12;
    //console.log(liHeight)
    this.setState({
      indexTop: (length - index) * liHeight + 38,
    });
  }
  //_getTop

  _onScroll(e) {
    const { index } = this.props;
    const { menuBottom } = this.state;

    const bounding = this.selector.current.getBoundingClientRect();
    //console.log(bounding.top, menuBottom, bounding.bottom)
    if (bounding.top < menuBottom && bounding.bottom > menuBottom) {
      PubSub.publish('PROJET_ENTER', {
        index: index,
      });
    }
  }

  _onClick() {
    const { data } = this.props;
    PubSub.publish('CAROUSEL_MODAL', {
      images: data.images,
    });
  }

  _onMouseEnter() {
    const { index } = this.props;
    PubSub.publish('PROJET_HOVER', {
      index: index,
    });
  }

  _onMouseLeave() {
    PubSub.publish('PROJET_HOVER', {
      index: null,
    });
  }

  _renderHero() {
    const { data } = this.props;
    //console.log(data)

    //let images = window.innerWidth < 768 ? [data.imageKeyMobile] : data.images.slice(0, 2)
    //if(!images[0])images = data.images.slice(0, 2)
    let images;
    if (data.imagesFeatured) images = data.imagesFeatured.slice(0, 2);
    else images = data.images.slice(0, 2);

    let heroClass = images.length > 1 ? 'col-md-6' : 'col-md-12';
    if (data.imageFeatured1Column) heroClass = 'col-md-6';
    //console.log(data.imageFeaturedFullWidth,heroClass)

    const hero = images.map((image, index) => (
      <div className={'col-xs-12 ' + heroClass + ' img-' + index} key={index}>
        <Figure
          input={image.gatsbyImageData}
          src={image.file.url}
          // src={image.fluid.src}
          // srcSet={image.fluid.srcSet}
          // sizes={image.fluid.sizes}
          fileName={image.file.fileName}
        />
        {/* <GatsbyImage
          image={getImage(image)}
          //   alt={image.alt ? image.alt : ''}
          //   style={{
          //     maxWidth: isMobile ? '300vw' : '80vw',
          //     background: 'black',
          //   }}
        /> */}
      </div>
    ));
    return hero;
  }

  render() {
    const { index, heroClass, indexTop } = this.state;
    const { data } = this.props;
    return (
      <div className="projet" id={data.slug} ref={this.selector}>
        <div className="index">
          <div
            className="inner"
            style={{
              //top: menuBottom+'px'
              top: indexTop + 'px',
            }}
          >
            {index}.{' '}
          </div>
        </div>
        <div className="content">
          <div
            className={'hero ' + heroClass}
            onClick={() => this._onClick()}
            role="widget"
            onKeyDown={() => this._onMouseEnter()}
            onMouseEnter={() => this._onMouseEnter()}
            onMouseLeave={() => this._onMouseLeave()}
          >
            <div className="row">{this._renderHero()}</div>
          </div>
          <h2 className="hidden-m">
            <span className="index-m">{index}.</span> {data.title}
          </h2>
          <div
            className="texte"
            dangerouslySetInnerHTML={{
              __html: data.texte.childMarkdownRemark.html,
            }}
          />
        </div>
      </div>
    );
  }
}

export default Projet;
