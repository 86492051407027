import React from "react"
//import PubSub from 'pubsub-js';
import Menu from "../components/menu"

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            menuClass: '',
            headerClass: ''
        }
        //this._onScroll = this._onScroll.bind(this)
    }

    componentDidMount(){
        // document.addEventListener("scroll", this._onScroll)
        // this._onScroll()
        if('ontouchstart' in window){
            //this._resize()
        }
    }

    _resize(){
        //const windowHeight = window.innerHeight
        const bgHeight = document.querySelector(".bg").getBoundingClientRect().height
        const innerHeight = this.refs.inner.getBoundingClientRect().height
        const scrollableHeight = innerHeight + bgHeight
console.log(bgHeight, innerHeight)
        this.refs.header.style.height = scrollableHeight+"px"
    }
    
    _onScroll(e){
        //console.log(window.pageYOffset)
        const menuClass = window.pageYOffset > 5 ? 'visible' : 'hidden'
        this.setState({
            menuClass: menuClass
        })

        // if( !('ontouchstart' in window) ){
        //     if(window.pageYOffset >= window.innerHeight){
        //         console.log("bg out")
        //         this.refs.header.style.height = (window.innerHeight/2)+"px"
        //     }
        // }
    }

    render(){
        const {headerClass, menuClass} = this.state
        const {data} = this.props
        return(
            <header ref="header" className={headerClass}>
                
                <div className="inner" ref="inner">
                    <div className="top">
                        <h1>{data.contentfulHeader.subtitle}</h1>
                        <div className="baseline" dangerouslySetInnerHTML={{ __html: data.contentfulHeader.baseline.childMarkdownRemark.html }} />
                        <div className="description" dangerouslySetInnerHTML={{ __html: data.contentfulHeader.texte.childMarkdownRemark.html }} />
                    </div>

                    <Menu 
                    menuClass={menuClass}
                    data={data.contentfulProjectsList.projets} />

                    {/* <div className="bottom">
                        <h1>{data.contentfulHeader.title}</h1>
                    </div> */}
                </div>

            </header>
        )
    }
}
export default Header

// export default (props) => (
//     //console.log(props)
//     <>
//     <header>
//         <div className="bg">
//             <div className="row">
//                 <div className="col-xs-6 col-md-3">
//                     <img src={sawS} alt="" />
//                 </div>
//                 <div className="col-xs-6 col-md-3"><img src={sawA} alt="" /></div>
//                 <div className="col-xs-12 col-md-6"><img src={sawW} alt="" className="sawW" /></div>
//             </div>
//         </div>
//         <div className="inner">
//             <div className="subtitle">{props.data.contentfulHeader.subtitle}</div>
//             <div className="baseline">{props.data.contentfulHeader.baseline}</div>
//             <div dangerouslySetInnerHTML={{ __html: props.data.contentfulHeader.texte.childMarkdownRemark.html }} />
//             <h1>{props.data.contentfulHeader.title}</h1>

            
//         </div>

//     </header>
    
//     </>
// )