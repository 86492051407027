import React from 'react';
import PubSub from 'pubsub-js';
import Slider from 'react-slick';
import Figure from './figure';

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      images: [],
      carouselClass: 'hidden',
      speed: 10,
      isTouch: false,
    };

    this._afterChange = this._afterChange.bind(this);
  }

  componentDidMount() {
    if ('ontouchstart' in window) {
      this.setState({
        isTouch: true,
        speed: 250,
      });
    }
    PubSub.subscribe('CAROUSEL_MODAL', (e, d) => {
      PubSub.publish('IS_CAROUSEL', { status: true });
      this.setState({
        images: d.images,
        carouselClass: 'visible',
      });

      setTimeout(() => {
        this._update();
      }, 250);
    });

    document.querySelector('.modal .bg').addEventListener('click', (evt) => {
      this._close();
    });

    document.querySelector('.modal .close').addEventListener('click', (evt) => {
      this._close();
    });

    document.addEventListener('keyup', (e) => {
      const key = e.key || e.keyCode;
      switch (key) {
        case 'Escape':
          this._close();
          break;

        case 'ArrowRight':
          document.querySelector('button.slick-next').click();
          break;

        case 'ArrowLeft':
          document.querySelector('button.slick-prev').click();
          break;

        default:
          break;
      }
    });
  }

  _close() {
    this.setState({
      carouselClass: 'hidden',
      images: [],
    });
    PubSub.publish('IS_CAROUSEL', { status: false });
  }

  _renderCarousel() {
    return this.state.images.map((image, index) => {
      //console.log(image)
      if (image.file.contentType === 'video/mp4') {
        return (
          <div className={'slide video'} key={index}>
            <div className="inner">
              <video>
                <source src={image.file.url} type={image.file.contentType} />
              </video>
            </div>
          </div>
        );
      } else {
        return (
          <div className={'slide image'} key={index}>
            <div className="inner">
              {/* <img src={image.file.url} alt="" /> */}
              <Figure
                input={image.gatsbyImageData}
                src={image.file.url}
                // srcSet={image.fluid.srcSet}
                // sizes={image.fluid.sizes}
                fileName={image.file.fileName}
              />
            </div>
          </div>
        );
      }
    });
  }

  _afterChange(index) {
    if (index === -1) return;
    this._update();
  }

  _update() {
    this._updateButtons();
    this._updateVideo();
  }

  _updateVideo() {
    const slide =
      document.querySelector('.slick-active img') ||
      document.querySelector('.slick-active video');
    if (slide.nodeName === 'VIDEO') {
      slide.play();
    } else {
      const videos = document.querySelectorAll('video');
      videos.forEach((video, index) => {
        if (!video.paused) video.pause();
      });
    }
  }

  _updateButtons() {
    const slide =
      document.querySelector('.slick-active img') ||
      document.querySelector('.slick-active video');

    const bounding = slide.getBoundingClientRect();
    const arrows = document.querySelectorAll('.carousel button');
    arrows.forEach((el, idx) => {
      el.style.height = bounding.height + 'px';
      el.style.top = bounding.top + 'px';
      el.style.width = bounding.width / 2 + 'px';

      if (idx === 0) {
        el.style.left = bounding.left + 'px';
      } else {
        el.style.right = window.innerWidth - bounding.right + 'px';
      }
    });

    // if(this.state.isTouch){
    //     const carousel = document.querySelector(".carousel")
    //     carousel.style.height = bounding.height+"px"
    //     //carousel.style.top = bounding.top+"px"
    //     console.log(window.innerHeight - bounding.height)
    //     carousel.style.top = (window.innerHeight - bounding.height) * 0.5+"px"
    // }
  }

  render() {
    const { carouselClass, speed } = this.state;
    const settings = {
      //autoplay: true,
      swipe: true,
      dots: false,
      infinite: true,
      speed: speed,
      slidesToShow: 1,
      slidesToScroll: 1,
      afterChange: this._afterChange,
      //onInit: () => console.log("on onInit"),
      // onReInit: () => {
      //     console.log("on onReInit")
      //     this.refs.slider.slickGoTo(0)
      // }
    };

    return (
      <div className={'modal ' + carouselClass}>
        <div className="bg"></div>
        <div className="carousel">
          <Slider key={Math.random() * 9} ref="slider" {...settings}>
            {this._renderCarousel()}
          </Slider>
        </div>
        <div className="close hidden-m">CLOSE</div>
      </div>
    );
  }
}

export default Footer;
