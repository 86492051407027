import React from 'react';
import { graphql } from 'gatsby';
//import Helmet from "react-helmet";
import PubSub from 'pubsub-js';

import Seo from '../components/seo';
import Bg from '../components/bg';
import Header from '../components/header';
import Footer from '../components/footer';
import Projets from '../components/projets';
import CarouselModal from '../components/carouse-modal';

require('../styles/index.scss');

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bodyClass: 'intro',
      mainClass: '',
    };
  }

  componentDidMount() {
    PubSub.subscribe('IS_CAROUSEL', (e, d) => {
      const bodyClass = d.status ? 'is-carousel' : '';
      this.setState({
        bodyClass: bodyClass,
      });
    });

    PubSub.subscribe('BG_OUT', (e, d) => {
      this.setState({
        bodyClass: '',
      });
      //document.querySelector("main").style.height = "auto"
    });

    window.onload = () => {
      setTimeout(() => {
        this.updateWrapperHeight();
      }, 100);
    };
  }

  updateWrapperHeight() {
    const bgHeight = document
      .querySelector('.bg')
      .getBoundingClientRect().height;
    const wrapHeight = document
      .querySelector('.wrap')
      .getBoundingClientRect().height;
    const newHeight = wrapHeight + bgHeight;
    //console.log(wrapHeight, bgHeight)
    document.querySelector('main').style.height = newHeight + 'px';
  }

  render() {
    const { bodyClass, mainClass } = this.state;
    const { data } = this.props;
    const {
      contentfulHeader,
      contentfulOptions,
      contentfulProjectsList,
      contentfulFooter,
    } = this.props.data;
    //console.log(bodyClass)
    return (
      <main className={mainClass}>
        <Seo data={contentfulHeader} bodyClass={bodyClass} />
        <Bg color="orange" context="header" colors={contentfulOptions} />
        <div className="wrap">
          <Header data={data} />
          <Projets data={contentfulProjectsList.projets} />
          <Footer data={contentfulFooter} colors={contentfulOptions} />
        </div>

        <CarouselModal />
      </main>
    );
  }
}

export default Index;

export const query = graphql`
  query {
    contentfulHeader {
      title
      subtitle
      baseline {
        childMarkdownRemark {
          html
        }
      }
      texte {
        childMarkdownRemark {
          html
        }
      }
      image {
        file {
          url
        }
      }
    }
    contentfulOptions {
      colorSHeads
      colorSTails
      colorAHeads
      colorATails
      colorWHeads
      colorWTails
    }
    contentfulProjectsList {
      projets {
        id
        slug
        title
        texte {
          childMarkdownRemark {
            html
          }
        }
        images {
          file {
            contentType
            fileName
            url
          }
          gatsbyImageData(width: 1500)
          # fluid(maxWidth: 1440) {
          #   sizes
          #   src
          #   srcSet
          # }
        }
        imageFeatured1Column
        imagesFeatured {
          file {
            url
            fileName
          }
          gatsbyImageData(width: 2000)
          # fluid(maxWidth: 1440) {
          #   sizes
          #   src
          #   srcSet
          # }
        }
      }
    }
    contentfulFooter {
      title
      contact
      instagram
      adress {
        childMarkdownRemark {
          html
        }
      }
      about {
        childMarkdownRemark {
          html
        }
      }
      credits {
        childMarkdownRemark {
          html
        }
      }
      texte {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`;
