import React from "react"
import Projet from "./projet"

class Projets extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        const {data} = this.props
        //console.log(data)
        return (
            <div className="projets">
                
                {data.map((node, index) => (
                    <Projet 
                    data={node} 
                    index={index} 
                    length={data.length}
                    key={index}
                    ref={"r-"+index} />
                ))}
            </div>
        )
    }
}

export default Projets